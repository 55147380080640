











































































































































































































import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';
import { bytesToString } from '@/util/bytes';

interface Validation {
  [index: string]: string|null,
  // eslint-disable-next-line camelcase
  item_id: string|null,
  name: string|null,
  size: string|null,
  type: string|null,
}

interface VuexBindings {
  detect: (idOrVanity: string) => ApiResource.Business,
  find: (b: string, c: string) => ApiResource.Commission,
  listItems: (c: string) => ApiResource.CommissionItem[],
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  data() {
    return {
      error: null as Error | null,
      file: {
        input: null as FileList|null,
        selected: null as File|null,
        validation: null,
      },
      form: {
        item_id: null,
        name: '',
        size: 0,
        type: '',
      },
      uploading: false,
      validation: {
        item_id: null as string|null,
        name: null as string|null,
        size: null as string|null,
        type: null as string|null,
      },
    };
  },
  computed: {
    ...mapGetters({
      detect: 'business/Detect',
      find: 'business/commission/Find',
      listItems: 'business/commission/item/List',
    }),
    COMMISSION_ID(): string {
      return this.$route.params.COMMISSION_ID;
    },
    VANITY(): string {
      return this.$route.params.VANITY;
    },
    accept(): string {
      return ['*/*'].join(',');
    },
    business(): ApiResource.Business {
      // Fallback to finding by ID automatically
      return this.detect(this.VANITY);
    },
    commission(): ApiResource.Commission {
      return this.find(this.business.id, this.COMMISSION_ID);
    },
    items(): ApiResource.CommissionItem[] {
      return this.listItems(this.COMMISSION_ID);
    },
  },
  watch: {
    'file.selected': function updateForm(to) {
      if (to) {
        this.form.name = to.name;
        this.form.size = to.size;
        this.form.type = to.type;
      } else {
        this.form.name = '';
        this.form.size = 0;
        this.form.type = '';
      }
    },
  },
  methods: {
    bytesToString,
    onFileInput(files: FileList) {
      [this.file.selected] = files;
    },
    submit() {
      if (this.validate()) return;

      this.error = null;

      this.uploadFile();
    },
    validate(): boolean {
      const validation: Validation = {
        item_id: null,
        name: null,
        size: null,
        type: null,
      };

      if (!this.form.name) {
        validation.name = 'notOptional';
      } else if (this.form.name.length > 255) {
        validation.name = 'length';
      }

      if (!this.form.size) {
        validation.size = 'notOptional';
      } else if (this.form.size > 5000000000) {
        validation.size = 'max';
      }

      if (!this.form.type) {
        validation.type = 'notOptional';
      }

      this.validation = validation;

      return !Object.keys(validation).every((k) => validation[k] === null);
    },
    async uploadFile(): Promise<void> {
      this.uploading = true;

      const BUSINESS_ID = this.business.id;
      const COMMISSION_ID = this.commission.id;
      const { VANITY } = this;

      const isB2Zone = [
        'nl-ams-2',
        'us-az-2',
      ].includes(this.commission.zone);
      const AB = this.$store.getters.AB_SEGMENT;
      const isSmallerThan100MB = this.form.size < 100000000;
      const isSmallerThanAbSegment = this.form.size < (AB * 100000);
      // ex. A/B-Segment 1000 => Files < 100.0 MB
      // ex. A/B-Segment  256 => Files <  25.6 MB
      const isRandomChance = AB / 1000 > Math.random();
      // Additionally, use random chance to trigger piped uploads

      const isPipedUpload = isSmallerThan100MB && (
        isB2Zone || (isSmallerThanAbSegment && isRandomChance)
      );

      console.debug(`[A/B] ${AB} >= ${this.form.size / 100000} ?${isRandomChance} =${isPipedUpload}`);

      const createFileResponse = await this.$store.dispatch('business/commission/file/Create', {
        BUSINESS_ID: this.business.id,
        COMMISSION_ID: this.commission.id,
        form: this.form,
      }).catch((e) => { this.error = e; });

      if (!createFileResponse) return this.resetProgress();

      const file: ApiResource.CommissionFile = createFileResponse.data;

      if (!this.file.selected) return void this.resetProgress();

      this.$store.dispatch('upload/upload', {
        // File to upload
        file: this.file.selected,
        // Upload instructions promise
        instructions: isPipedUpload
          ? this.$store.dispatch('business/commission/file/UploadPipeInstructions', {
            BUSINESS_ID,
            COMMISSION_ID,
            FILE_ID: file.id,
          })
          : this.$store.dispatch('business/commission/file/Upload', {
            BUSINESS_ID,
            COMMISSION_ID,
            FILE_ID: file.id,
          }).then((res) => (res.data ? res.data : res)),
        // Optional post-upload confirmation promise
        afterUpload: (res: any) => {
          let data = null;
          if (res) data = res.status !== 204 ? res.data : null;

          if (!isPipedUpload) {
            this.$store.dispatch('business/commission/file/Confirm', {
              BUSINESS_ID,
              COMMISSION_ID,
              FILE_ID: file.id,
              data,
            });
          }

          this.$a.goal(
            this.$a.goals.CommissionFileUpload,
            Math.ceil((2 * 12 * 5) * (file.size / 1e9)),
          );
        },
        onUploadCancel: () => {
          this.$store.dispatch('business/commission/file/Delete', {
            BUSINESS_ID,
            COMMISSION_ID,
            FILE_ID: file.id,
          });
        },
        onUploadError: () => {
          this.$store.dispatch('business/commission/file/Delete', {
            BUSINESS_ID,
            COMMISSION_ID,
            FILE_ID: file.id,
          });
        },
        routerTo: {
          name: 'dashboard.commission.file',
          params: {
            VANITY,
            COMMISSION_ID,
            FILE_ID: file.id,
          },
        },
        businessId: BUSINESS_ID,
        commissionId: COMMISSION_ID,
      });

      return void this.startNewUploadForm();
    },
    reset() {
      this.resetProgress();
      this.resetForm();
    },
    resetForm() {
      this.file.input = null;
      this.file.selected = null;
      this.form.item_id = null;
      this.form.name = '';
      this.form.size = 0;
      this.form.type = '';
      this.validation.item_id = null;
      this.validation.name = null;
      this.validation.size = null;
      this.validation.type = null;
    },
    resetProgress() {
      this.uploading = false;
      // this.progress.value = null;
      // this.progress.max = 1;
    },
    startNewUploadForm() {
      this.reset();
      this.$toast({
        message: this.$t('upload.background').toString(),
        classes: ['is-link max-w-lg'],
        pauseOnHover: true,
        position: 'top-right',
        links: [
          {
            label: this.$t('files').toString(),
            to: {
              name: 'dashboard.commission.files',
              params: {
                VANITY: this.VANITY,
                COMMISSION_ID: this.COMMISSION_ID,
              },
            },
            classes: 'is-link',
          },
          {
            label: this.$t('upload.manager').toString(),
            to: {
              name: 'uploads',
            },
            classes: 'has-text-primary',
          },
        ],
      });
    },
    handleError(e: Error) {
      this.resetProgress();
      this.error = e;
    },
    failed() {
      this.resetProgress();
    },
  },
});
